import { DatoComponentsWrapper } from "components/common/DatoComonentsWrappeer/DatoComponentsWrapper";
import { useTranslationContext } from "context/translation";
import { Maybe } from "graphql/jsutils/Maybe";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { createDatoClient } from "services/datocms";
import { ComponentsWrapperFragment, getSdk } from "services/datocms/generated";
import { createQueryParamsForCaching } from "utils/create-query-params";
import PageLayout from "../components/layout/PageLayout";

const Custom404 = () => {
  const [components, setComponents] =
    useState<Maybe<ComponentsWrapperFragment>>(null);

  const router = useRouter();

  useEffect(() => {
    getSdk(
      createDatoClient(
        router?.isPreview,
        createQueryParamsForCaching({ queryType: "Page404" }),
      ),
    )
      .Page404()
      .then((res) => {
        setComponents(res?.page404?.components);
      });
  }, [router?.isPreview]);

  const { page404 } = useTranslationContext();

  return (
    <PageLayout>
      <div className="container flex flex-col gap-10 py-10 md:py-20">
        <div className="flex gap-4 justify-between">
          <h1 className="text-gray-900">{page404?.header}</h1>
          <h1 className="text-gray-300">404</h1>
        </div>
        <p className=" text-gray-500 text-[15px] font-medium">
          {page404?.description}
        </p>
        <Link
          className="text-primary text-[15px] font-medium underline"
          href="/"
        >
          {page404?.linkText}
        </Link>
      </div>
      {<DatoComponentsWrapper components={components} />}
    </PageLayout>
  );
};

export default Custom404;
